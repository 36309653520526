import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StaticPageTemplate = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description || data.markdownRemark.excerpt}
      />
      <article>
        <header><h1 style={{ marginTop: 0 }}>{data.markdownRemark.frontmatter.title}</h1></header>
        <section>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </section>
      </article>
    </Layout>
  );
}

export default StaticPageTemplate

export const pageQuery = graphql`
  query StaticPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
